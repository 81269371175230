<template>
  <div>
    <b-col cols="12" xl="12" lg="12" md="12">
      <b-card>
        <b-row>
          <!-- Right Col: Table -->
          <b-col cols="6" xl="6">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="font-weight-bold">ISP Name</span>
                </th>
                <td class="pb-50">{{ settlementInfo.isp.company_name }}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold">Bank Transaction ID</span>
                </th>
                <td class="pb-50 text-capitalize">{{ settlementInfo.bank_trans_id}}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="StarIcon" class="mr-75" />
                  <span class="font-weight-bold">Remark</span>
                </th>
                <td class="pb-50 text-capitalize">{{ settlementInfo.remarks}}</td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="FlagIcon" class="mr-75" />
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50">{{ settlementInfo.status}}</td>
              </tr>
            </table>
          </b-col>
          <b-col cols="6" xl="6">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50">
                        <b-avatar
                            variant="light-success"
                            rounded
                        >
                            <feather-icon
                                icon="DollarSignIcon"
                                size="18"
                            />
                        </b-avatar>
                        <span class="font-weight-bold">Transaction Amount</span>
                    </th>
                    <td class="pb-50 text-capitalize font-weight-bolder text-success">{{ summery.transaction_amount}} BDT</td>
                </tr>
              <tr>
                <th class="pb-50">
                  <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
                  <span class="font-weight-bold">Processing Free</span>
                </th>
                <td class="pb-50 text-capitalize font-weight-bolder text-success">{{ summery.total_processing_fees }} BDT</td>
              </tr>

              <tr>
                <th class="pb-50">
                   <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
                  <span class="font-weight-bold">Total Payable Amount</span>
                </th>
                <td class="pb-50 text-capitalize font-weight-bolder text-success">{{ summery.receivable_amount}} BDT</td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-card-code>
      <h3>Settlement Details List</h3>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        theme="my-theme"
      >
        <!-- Column: Actions -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'invoice_code'" class="text-nowrap">
            <span>{{ props.row.invoice_code }}</span>
          </span>
          <span
            v-if="props.column.field == 'paid_date'"
            class="text-nowrap"
          >
            <span>{{ props.row.transaction_date }}</span>
          </span>
          <span
            v-if="props.column.field == 'user'"
            class="text-nowrap"
          >
            <span>{{ JSON.parse(props.row.invoice_data).isp_user_name }}</span>
          </span>
            <div v-if="props.column.field === 'pending_amount'">
                {{ props.row.transaction_amount }}
            </div>
            <div v-if="props.column.field === 'charge_amount'">
                {{ props.row.processing_fees }}
            </div>
            <div v-if="props.column.field === 'total_payable_amount'">
                {{ props.row.receivable_amount }}
            </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '300', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
  BCard,
} from "bootstrap-vue";
import { ISP_LIST, SETTLEMENT_DETAILS } from "@core/services/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
// import store from '@/store/index'
import store from "@/store";
import { INVOICE_LIST } from "@core/services/api.js";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCard,
  },
  data() {
    return {
      toggle: false,
      isShow: false,
      pageLength: 100,
      dir: false,
      required,
      columns: [
          { field: "paid_date", label: "Transaction Date", sortable: true },
          { field: "user", label: "User Name", sortable: true },
          { field: "invoice_code", label: "Invoice Code", sortable: true },
          {
              field: 'pending_amount',
              label: 'Transaction Amount',
              // sortable: true,
              formatter: val => `${val} BDT`,
          },
          {
              field: 'charge_amount',
              label: 'Processing Fees',
              // sortable: true,
              formatter: val => `${val} BDT`,
          }, {
              field: 'total_payable_amount',
              label: 'Credit Amount (Receivable)',
              // sortable: true,
              formatter: val => `${val} BDT`,
          }
        /*{ field: "invoice_code", label: "Invoice Code", sortable: true },
        { field: "transaction_date", label: "Payment Date", sortable: true },
        { field: "processing_fees", label: "Processing Fees", sortable: true },
        {
          field: "transaction_amount",
          label: "Transaction Amount",
          sortable: true,
        },
        { field: "receivable_amount", label: "Total Amount", sortable: true },*/
      ],
      rows: [],
      invoice_ids: [],
      isp_list: [],
      isp_id: "",
      searchTerm: "",
      from_date: "",
      to_date: "",
      bank_trans_id: null,
      remakr: null,
      summery: "",
      settlementInfo: [
       ]
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.$http
      .get(
        process.env.VUE_APP_BASEURL +
          SETTLEMENT_DETAILS +
          router.currentRoute.params.settlementId,
        {
          params: {},
        }
      )
      .then((res) => {
        this.rows = res.data.data.settlementDetails;
        this.summery = res.data.data.summery;
        this.settlementInfo = res.data.data.settlementInfo;
      });
  },
  methods: {
    idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then((res) => {
          this.isp_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
  },
  mounted() {
    this.idpListData();
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
